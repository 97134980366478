//Backend URL:

//local
// export const BASE_URL = "http://localhost:4000";
//Stagging
// export const BASE_URL = "https://dygres.dev.seraphic.io/node";
//Live
// export const BASE_URL = "http://54.164.159.66:4000";
export const BASE_URL = "https://api.dygres.com";


